import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index/index.vue'),
      },
      // 运维系统 
      {
        path: '/operation',
        name: 'operation',
        component: () => import('../views/operation/index.vue'),
      },
      // 数字孪生 
      {
        path: '/digitalTwins',
        name: 'digitalTwins',
        component: () => import('../views/digitalTwins/index.vue'),
      },
      // 组态模块
      {
        path: '/configuration',
        name: 'configuration',
        component: () => import('../views/configuration/index.vue'),
      },
      // 产品详情
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
      },
      // 技术与服务
      {
        path: '/services',
        name: 'services',
        component: () => import('../views/services/index.vue'),
      },
      // 关于迪倍特
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/index.vue'),
      },
      // 联系我们
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs/index.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/'||to.path == '/home') {
    next('/index')
  } else {
    next()
  }
})

export default router
